import './App.css';
import {ROUTES} from "./Services/Router/routes.tsx";
import {Route, Routes, useLocation} from "react-router-dom";
import DefaultPage from "./components/pages/DefaultPage";
import NotFoundPage from "./components/pages/NotFoundPage";
import {useEffect} from "react";
import {Helmet} from "react-helmet";


function App() {
    const location = useLocation();
    useEffect(() => {
        window.gtag("event", "page_view", {
            page_path: location.pathname + location.search + location.hash,
            page_search: location.search,
            page_hash: location.hash,
        });
    }, [location]);
    return (
        <div className="App">
            <Helmet>
                <title>Watskebeurt.nl - P2000</title>
                <link rel="canonical" href="https://www.watskebeurt.nl/"/>
            </Helmet>
            <Routes>
                {ROUTES.default.map((rt) =>
                    (<Route path={rt.route} element={<DefaultPage>{rt.children}</DefaultPage>} key={rt.route}/>)
                )})
                <Route path='*' element={<NotFoundPage />}/>
            </Routes>
        </div>
    );
}

export default App;
