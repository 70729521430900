export interface P2kExtraReceiver {
    __typename?: 'MessageReceiver';
    CapCode: string;
    Function: string;
    Region: string;
    Discipline: string;
}

export interface P2kLocation {
    __typename?: 'MessageLocation';
    City: string;
    Street: string;
    Lat: number;
    Long: number;
}

export interface PagedMessages {
    __typename?: 'PagedMessages';
    Total: number;
    Page: number;
    Limit: number;
    GPSLocation?: GPSLocation | null;
    Messages: P2kMessage[];
}

interface GPSLocation {
    __typename?: 'GPSLocation';
    Lat: number;
    Long: number;
}

export interface P2kPriority {
    __typename?: 'MessagePriority';
    Priority: number;
    Category: number;
}

export interface P2kMessage {
    __typename?: 'Message' | undefined;
    ID: number;
    UUID: string;
    Type: string;
    Date?: any | null;
    Message: string;
    RawBody: string;
    Unit: string;
    Receiver: string;
    ReceivedAt?: any | null;
    TestMessage: boolean;
    Command: P2kMessageCommand;
    Priority: P2kPriority;
    ExtraReceivers: P2kExtraReceiver[];
    Location?: P2kLocation | null;
    Dia: boolean;
    ParseTime: number;
}

export interface P2kMessageCommand {
    __typename?: 'MessageCommand';
    Command: string;
    Args: Array<string | null>;
}

export enum P2kMessagePriorityUnit {
    Unknown = 0,
    Ambulance = 1,
    FireDepartment = 2,
    Police = 3,
    LifeLiner = 4,
}
export enum P2kMessagePriorityPriority {
    Unknown = 0,
    PriorityAmbulanceOne = 1,
    PriorityAmbulanceTwo = 2,
    PriorityAmbulanceTransportPlanned = 3,
    PriorityAmbulanceTransportUnplanned = 4,
    PriorityFireBrigadeOne = 5,
    PriorityFireBrigadeTwo = 6,
    PriorityFireBrigadeThree = 7,
    PriorityFireBrigadeFour= 8,
    PriorityFireBrigadeFive = 9,

}

export enum P2KMessageCommandType {
    Unknown = "Unknown",
    Command = "Command",
    P2000 = "P2000",
}

export const UnitNames: { [key: number]: string } = {
    0: "Onbekend",
    1: "Ambulance",
    2: "Brandweer",
    3: "Politie",
    4: "Lifeliner",
}

export const PriorityNames: { [key: number]: string } = {
    0: "Onbekend",
    1: "Spoed",
    2: "Gepland vervoer",
    3: "Ongepland vervoer",
    4: "Spoed",
    5: "Gepaste spoed",
    6: "Weinig spoed",
    7: "Komt wel",
    8: "Mwa kijk maar",
    9: "MWaa niets aan de hand",
};

export interface ICity {
    __typename?: 'City';
    Name: string;
    Abbreviation: string;
}



// enum MarkerColor {
//     "red" = P2kMessagePriorityUnit.FireDepartment,
//     "blue" = P2kMessagePriorityUnit.Police,
//     "yellow" = P2kMessagePriorityUnit.Ambulance,
//     "yellow" = P2kMessagePriorityUnit.LifeLiner,
//
// }