import MenuPage from "../../components/pages/MenuPage";
import MapComponent from "../../components/content/MapComponent";
import FeedComponent from "../../components/content/FeedComponent";
import LatestMessagesComponent from "../../components/content/LatestMessagesComponent";
import CityMessagesComponent from "../../components/content/CityMessagesComponent";
import CapMessagesComponent from "../../components/content/CapMessagesComponent";
import CitiesComponent from "../../components/content/CitiesComponent";
import CapCodesComponent from "../../components/content/CapCodesComponent";
import MapLocalComponent from "../../components/content/MapLocalComponent";
import MessageDetailComponent from "../../components/content/MessageDetail";
import TelegramComponent from "../../components/content/TelegramComponent";
import PrivacyComponent from "../../components/content/PrivacyComponent";
import CastricumComponent from "../../components/content/CastricumComponent";

export enum ROUTE  {
    ROOT = "/",
    LATEST =  "/laatste-112-meldingen",
    LATEST_OLD =  "/laatste-meldingen",
    CITIES =  "/plaatsen",
    CITY =  "/plaatsen/:city",
    CITY_MAP =  "/kaart/:city",
    CAP_CODES =  "/alarmcodes",
    TELEGRAM_BOT =  "/telegram-bot",
    CAP_CODE =  "/alarmcodes/:cap",
    MESSAGE_DETAIL =  "/bericht/:id",
    PRIVACY_POLICY =  "/privacy-policy",
    CASTRICUM =  "/castricum"

}

export const ROUTES = {
    default: [
        {
            route: ROUTE.ROOT,
            children: [<MenuPage key={ROUTE.ROOT}><MapComponent /><FeedComponent /></MenuPage>]
        },
        {
            route: ROUTE.LATEST,
            children: [<MenuPage key={ROUTE.LATEST}><LatestMessagesComponent /></MenuPage>]
        },
        {
            route: ROUTE.LATEST_OLD,
            children: [<MenuPage key={ROUTE.LATEST}><LatestMessagesComponent /></MenuPage>]
        },
        {
            route: ROUTE.CITIES,
            children: [<MenuPage key={ROUTE.CITIES}><CitiesComponent /></MenuPage>]
        },
        {
            route: ROUTE.CITY,
            children: [<MenuPage key={ROUTE.CITY}><CityMessagesComponent /></MenuPage>]
        },
        {
            route: ROUTE.CAP_CODES,
            children: [<MenuPage key={ROUTE.CAP_CODES}><CapCodesComponent /></MenuPage>]
        },
        {
            route: ROUTE.CAP_CODE,
            children: [<MenuPage key={ROUTE.CAP_CODE}><CapMessagesComponent /></MenuPage>]
        },
        {
            route: ROUTE.CITY_MAP,
            children: [<MenuPage key={ROUTE.CAP_CODE}><MapLocalComponent css="" /></MenuPage>]
        },
        {
            route: ROUTE.MESSAGE_DETAIL,
            children: [<MenuPage key={ROUTE.CAP_CODE}><MessageDetailComponent /></MenuPage>]
        },
        {
            route: ROUTE.TELEGRAM_BOT,
            children: [<MenuPage key={ROUTE.TELEGRAM_BOT}><TelegramComponent /></MenuPage>]
        },
        {
            route: ROUTE.PRIVACY_POLICY,
            children: [<MenuPage key={ROUTE.PRIVACY_POLICY}><PrivacyComponent /></MenuPage>]
        },
        {
            route: ROUTE.CASTRICUM,
            children: [<MenuPage key={ROUTE.CASTRICUM}><CastricumComponent /></MenuPage>]
        },
    ],
}

