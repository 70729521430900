import React from 'react'

interface Props {
}

const  TelegramComponent: React.FC<Props> = () => {

    return (
        <div className="mt-16 mb-24 mx-auto max-w-7xl px-2 sm:px-4 lg:px-8 h-auto lg:mt-24 p-10 bg-white rounded-md shadow">
            <div className="px-4 sm:px-0">
                <h1 className="text-base font-semibold leading-7 text-gray-900">Telegram bot</h1>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                    Telegram is een chat app die je op je telefoon, tablet en computer kunt gebruiken. Je kunt er berichten mee versturen en ontvangen. Je kunt er ook foto's, video's, stickers, spraakberichten en locaties mee versturen. Telegram is een gratis app en is beschikbaar voor Android, iOS, Windows Phone, Windows, macOS en Linux.
                </p>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                    Via Telegram versturen wij ook de 112 meldingen direct naar jouw telefoon. Je kunt je aanmelden door <a href="https://t.me/p2000pieperbot">hier (https://t.me/p2000pieperbot)</a> te klikken.

                    Gebruik de onderstaande commando's om de bot te gebruiken:
                    <table className="mt-3 w-full">
                        <thead>
                            <tr className="font-bold">
                                <td className="p-5">Commando</td>
                                <td className="p-5">Uitleg</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="whitespace-nowrap p-5 font-medium align-top">/capcode add capcode[,capcode,capcode]</td>
                                <td className="p-5">Wil je direct een specifieke capcode ontvangen dan kan dit via dit commando. Heb je meerdere capcodes die je direct wilt toevoegen plaats er dan een
                                    comma tussen.
                                </td>
                            </tr>
                            <tr>
                                <td className="whitespace-nowrap p-5 font-medium  align-top">/capcode del capcode[,capcode,capcode]</td>
                                <td className="p-5">Verwijder een capcode uit de lijst en ontvang geen berichten meer van deze capcode. Heb je meerdere capcodes die je direct wilt verwijderen plaats er
                                    dan een comma tussen.
                                </td>
                            </tr>
                            <tr>
                                <td className="whitespace-nowrap p-5 font-medium  align-top">/capcode list</td>
                                <td className="p-5">Laat een lijst zien van de capcodes die je volgt</td>
                            </tr>
                            <tr>
                                <td className="whitespace-nowrap p-5 font-medium align-top">/city add plaats[,plaats,plaats]</td>
                                <td className="p-5">Wil je berichten ontvangen uit jou of een specifieke plaats dan kan dit via dit commando. Heb je meerdere plaatsen die je direct wilt toevoegen plaats
                                    er dan een comma tussen.
                                </td>
                            </tr>
                            <tr>
                                <td className="whitespace-nowrap p-5 font-medium  align-top">/city del plaats[,plaats,plaats]</td>
                                <td className="p-5">Verwijder een plaats uit de lijst en ontvang geen berichten meer van deze plaats. Heb je meerdere plaatsen die je direct wilt verwijderen plaats er
                                    dan een comma tussen.
                                </td>
                            </tr>
                            <tr>
                                <td className="whitespace-nowrap p-5 font-medium  align-top">/city list</td>
                                <td className="p-5">Laat een lijst zien van de plaatsen die je volgt.</td>
                            </tr>
                            <tr>
                                <td className="whitespace-nowrap p-5 font-medium  align-top">/sleep on</td>
                                <td className="p-5">Aan je slapen aanzet blijven je instellingen bewaard maar krijg je geen berichten meer</td>
                            </tr>
                            <tr>
                                <td className="whitespace-nowrap p-5 font-medium  align-top">/sleep off</td>
                                <td className="p-5">Als je slaap uitzet krijg je weer berichten toegezonden.</td>
                            </tr>
                            <tr>
                                <td className="whitespace-nowrap p-5 font-medium  align-top">/me</td>
                                <td className="p-5">Geef een overzicht van jou instellingen.</td>
                            </tr>
                            <tr>
                                <td className="whitespace-nowrap p-5 font-medium  align-top">/help</td>
                                <td className="p-5">Zit je in telegram en wil je dit help menu nog een keer zien type dan dit commando.</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={2} className="text-center">* elementen tussen [] (blok haken zijn optioneel)</td>
                            </tr>
                        </tfoot>
                    </table>
                </p>
            </div>
        </div>
    )
}

export default TelegramComponent