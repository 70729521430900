import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  Time: { input: any; output: any; }
  Uint: { input: any; output: any; }
};

export type City = {
  __typename?: 'City';
  /** The abbreviation of the city */
  Abbreviation: Scalars['String']['output'];
  /** The alternative name of the city */
  AlternativeName: Scalars['String']['output'];
  /** The latitude of the city */
  Lat: Scalars['Float']['output'];
  /** The longitude of the city */
  Long: Scalars['Float']['output'];
  /** The name of the city */
  Name: Scalars['String']['output'];
  /** ID of the city */
  UUID: Scalars['String']['output'];
};

export type GpsLocation = {
  __typename?: 'GPSLocation';
  /** Latitude of the detected location */
  Lat: Scalars['Float']['output'];
  /** Longitude of the detected location */
  Long: Scalars['Float']['output'];
};

export type HeartBeat = {
  __typename?: 'HeartBeat';
  /** Is alive or not */
  Alive?: Maybe<Scalars['Boolean']['output']>;
  /** Last hear beat delay in seconds */
  Last: Scalars['Float']['output'];
};

export type Message = {
  __typename?: 'Message';
  Command: MessageCommand;
  Date?: Maybe<Scalars['Time']['output']>;
  Dia: Scalars['Boolean']['output'];
  ExtraReceivers: Array<MessageReceiver>;
  ID: Scalars['Int']['output'];
  Location?: Maybe<MessageLocation>;
  Message: Scalars['String']['output'];
  ParseTime: Scalars['Float']['output'];
  Priority: MessagePriority;
  RawBody: Scalars['String']['output'];
  ReceivedAt?: Maybe<Scalars['Time']['output']>;
  Receiver: Scalars['String']['output'];
  TechCde: Scalars['String']['output'];
  TechCode: Scalars['String']['output'];
  TestMessage: Scalars['Boolean']['output'];
  Type: Scalars['String']['output'];
  UUID: Scalars['String']['output'];
  Unit: Scalars['String']['output'];
};

export type MessageCommand = {
  __typename?: 'MessageCommand';
  Args: Array<Maybe<Scalars['String']['output']>>;
  Command: Scalars['String']['output'];
};

export type MessageLocation = {
  __typename?: 'MessageLocation';
  /** The detected city if available */
  City: Scalars['String']['output'];
  /** Latitude of the detected location. If no street is detected, the location is the center of the city */
  Lat: Scalars['Float']['output'];
  /** Longitude of the detected location If no street is detected, the location is the center of the city */
  Long: Scalars['Float']['output'];
  /** The detected street if available */
  Street: Scalars['String']['output'];
};

export type MessagePriority = {
  __typename?: 'MessagePriority';
  Category: Scalars['Int']['output'];
  Priority: Scalars['Int']['output'];
};

export type MessageReceiver = {
  __typename?: 'MessageReceiver';
  /** The capcode of the receiver */
  CapCode: Scalars['String']['output'];
  /** Messages received by this receiver. only used on alarmeer codes page */
  Count: Scalars['Int']['output'];
  /** The discipline of the receiver */
  Discipline: Scalars['String']['output'];
  /** The name of the receiver */
  Function: Scalars['String']['output'];
  /** The region of the receiver */
  Region: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  DisableNotificationsForDevice: Scalars['Boolean']['output'];
  EnableNotificationsForDevice: Scalars['Boolean']['output'];
  RemoveNotification: Scalars['Boolean']['output'];
  ResetBadgeCount: Scalars['Boolean']['output'];
  SetCriticalForNotification: NotificationStatus;
  SetNotificationForDevice: NotificationStatus;
  SetSound: Scalars['Boolean']['output'];
};


export type MutationDisableNotificationsForDeviceArgs = {
  OS?: InputMaybe<Scalars['String']['input']>;
  mute?: InputMaybe<Scalars['Boolean']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEnableNotificationsForDeviceArgs = {
  OS?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRemoveNotificationArgs = {
  OS?: InputMaybe<Scalars['String']['input']>;
  notification?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationResetBadgeCountArgs = {
  OS?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetCriticalForNotificationArgs = {
  OS?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  notification?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetNotificationForDeviceArgs = {
  OS?: InputMaybe<Scalars['String']['input']>;
  mute?: InputMaybe<Scalars['Boolean']['input']>;
  notification?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetSoundArgs = {
  OS?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<SoundType>;
  sound?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationStatus = {
  __typename?: 'NotificationStatus';
  /** Is message send as critical */
  Critical: Scalars['Boolean']['output'];
  /** Internal ID for this notification */
  ID: Scalars['Int']['output'];
  /** The mute status of the notification */
  Mute: Scalars['Boolean']['output'];
  /** The status of the action */
  Status: Scalars['Boolean']['output'];
  /** The subject of the notification */
  Subject: Scalars['String']['output'];
};

export type PagedCapCodes = {
  __typename?: 'PagedCapCodes';
  /** The P2000 Messages for the current page */
  CapCodes: Array<MessageReceiver>;
  /** The number of items per page */
  Limit: Scalars['Int']['output'];
  /** The current page number */
  Page: Scalars['Int']['output'];
  /** The total number of items */
  Total: Scalars['Int']['output'];
};

export type PagedMessages = {
  __typename?: 'PagedMessages';
  /** Optional: GPS location */
  GPSLocation?: Maybe<GpsLocation>;
  /** The number of items per page */
  Limit: Scalars['Int']['output'];
  /** The P2000 Messages for the current page */
  Messages: Array<Message>;
  /** The current page number */
  Page: Scalars['Int']['output'];
  /** The total number of items */
  Total: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  FindByCapCode: PagedMessages;
  FindByCity: PagedMessages;
  FindCapCode: Array<MessageReceiver>;
  GetAllCapCodes: Array<MessageReceiver>;
  GetCapCode: MessageReceiver;
  GetCapCodes: PagedCapCodes;
  GetCities: Array<City>;
  GetCity: City;
  GetLastForFavorites: PagedMessages;
  GetMessage: Message;
  GetMessagesForCitySearch: PagedMessages;
  GetMessagesForLocation: PagedMessages;
  GetMessagesForSearch: PagedMessages;
  Heartbeat: HeartBeat;
  LastMessages: PagedMessages;
};


export type QueryFindByCapCodeArgs = {
  capCode?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindByCityArgs = {
  city?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindCapCodeArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetCapCodeArgs = {
  capCode?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetCapCodesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetCityArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetLastForFavoritesArgs = {
  capCodes?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  cities?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetMessageArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMessagesForCitySearchArgs = {
  city?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMessagesForLocationArgs = {
  distance?: InputMaybe<Scalars['Int']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  long?: InputMaybe<Scalars['Float']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetMessagesForSearchArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLastMessagesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export enum SoundType {
  All = 'ALL',
  Cap = 'CAP',
  City = 'CITY'
}

export type DisableNotificationsForDeviceMutationVariables = Exact<{
  OS?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  mute?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type DisableNotificationsForDeviceMutation = { __typename?: 'Mutation', DisableNotificationsForDevice: boolean };

export type EnableNotificationsForDeviceMutationVariables = Exact<{
  OS?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
}>;


export type EnableNotificationsForDeviceMutation = { __typename?: 'Mutation', EnableNotificationsForDevice: boolean };

export type RemoveNotificationMutationVariables = Exact<{
  OS?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  notification?: InputMaybe<Scalars['String']['input']>;
}>;


export type RemoveNotificationMutation = { __typename?: 'Mutation', RemoveNotification: boolean };

export type SetNotificationForDeviceMutationVariables = Exact<{
  OS?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  notification?: InputMaybe<Scalars['String']['input']>;
  mute?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type SetNotificationForDeviceMutation = { __typename?: 'Mutation', SetNotificationForDevice: { __typename?: 'NotificationStatus', Status: boolean, ID: number } };

export type GetLastForFavoritesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  cities?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  capcodes?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>>;
}>;


export type GetLastForFavoritesQuery = { __typename?: 'Query', GetLastForFavorites: { __typename?: 'PagedMessages', Total: number, Page: number, Limit: number, GPSLocation?: { __typename?: 'GPSLocation', Lat: number, Long: number } | null, Messages: Array<{ __typename?: 'Message', ID: number, UUID: string, Type: string, Date?: any | null, Message: string, RawBody: string, Unit: string, Receiver: string, ReceivedAt?: any | null, TestMessage: boolean, Dia: boolean, ParseTime: number, Command: { __typename?: 'MessageCommand', Command: string, Args: Array<string | null> }, Priority: { __typename?: 'MessagePriority', Priority: number, Category: number }, ExtraReceivers: Array<{ __typename?: 'MessageReceiver', CapCode: string, Function: string, Region: string, Discipline: string }>, Location?: { __typename?: 'MessageLocation', City: string, Street: string, Lat: number, Long: number } | null }> } };

export type GetMessagesForCitySearchQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetMessagesForCitySearchQuery = { __typename?: 'Query', GetMessagesForCitySearch: { __typename?: 'PagedMessages', Total: number, Page: number, Limit: number, Messages: Array<{ __typename?: 'Message', ID: number, UUID: string, Type: string, Date?: any | null, Message: string, RawBody: string, Unit: string, Receiver: string, ReceivedAt?: any | null, TestMessage: boolean, Dia: boolean, ParseTime: number, Command: { __typename?: 'MessageCommand', Command: string, Args: Array<string | null> }, Priority: { __typename?: 'MessagePriority', Priority: number, Category: number }, ExtraReceivers: Array<{ __typename?: 'MessageReceiver', CapCode: string, Function: string, Region: string, Discipline: string }>, Location?: { __typename?: 'MessageLocation', City: string, Street: string, Lat: number, Long: number } | null }> } };

export type HeartBeatQueryVariables = Exact<{ [key: string]: never; }>;


export type HeartBeatQuery = { __typename?: 'Query', Heartbeat: { __typename?: 'HeartBeat', Alive?: boolean | null, Last: number } };

export type FindByCapCodeQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  capCode?: InputMaybe<Scalars['Int']['input']>;
}>;


export type FindByCapCodeQuery = { __typename?: 'Query', FindByCapCode: { __typename?: 'PagedMessages', Total: number, Page: number, Limit: number, GPSLocation?: { __typename?: 'GPSLocation', Lat: number, Long: number } | null, Messages: Array<{ __typename?: 'Message', ID: number, UUID: string, Type: string, Date?: any | null, Message: string, RawBody: string, Unit: string, Receiver: string, ReceivedAt?: any | null, TestMessage: boolean, Dia: boolean, ParseTime: number, Command: { __typename?: 'MessageCommand', Command: string, Args: Array<string | null> }, Priority: { __typename?: 'MessagePriority', Priority: number, Category: number }, ExtraReceivers: Array<{ __typename?: 'MessageReceiver', CapCode: string, Function: string, Region: string, Discipline: string }>, Location?: { __typename?: 'MessageLocation', City: string, Street: string, Lat: number, Long: number } | null }> } };

export type FindByCityQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
}>;


export type FindByCityQuery = { __typename?: 'Query', FindByCity: { __typename?: 'PagedMessages', Total: number, Page: number, Limit: number, GPSLocation?: { __typename?: 'GPSLocation', Lat: number, Long: number } | null, Messages: Array<{ __typename?: 'Message', ID: number, UUID: string, Type: string, Date?: any | null, Message: string, RawBody: string, Unit: string, Receiver: string, ReceivedAt?: any | null, TestMessage: boolean, Dia: boolean, ParseTime: number, Command: { __typename?: 'MessageCommand', Command: string, Args: Array<string | null> }, Priority: { __typename?: 'MessagePriority', Priority: number, Category: number }, ExtraReceivers: Array<{ __typename?: 'MessageReceiver', CapCode: string, Function: string, Region: string, Discipline: string }>, Location?: { __typename?: 'MessageLocation', City: string, Street: string, Lat: number, Long: number } | null }> } };

export type GetCapCodeQueryVariables = Exact<{
  capCode?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetCapCodeQuery = { __typename?: 'Query', GetCapCode: { __typename?: 'MessageReceiver', CapCode: string, Function: string, Region: string, Discipline: string } };

export type GetCapCodesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetCapCodesQuery = { __typename?: 'Query', GetCapCodes: { __typename?: 'PagedCapCodes', Total: number, Page: number, Limit: number, CapCodes: Array<{ __typename?: 'MessageReceiver', CapCode: string, Function: string, Region: string, Discipline: string }> } };

export type GetCitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCitiesQuery = { __typename?: 'Query', GetCities: Array<{ __typename?: 'City', Name: string, Abbreviation: string }> };

export type GetMessageQueryVariables = Exact<{
  uuid?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetMessageQuery = { __typename?: 'Query', GetMessage: { __typename?: 'Message', ID: number, UUID: string, Type: string, Date?: any | null, TechCode: string, RawBody: string, Unit: string, Receiver: string, Message: string, TestMessage: boolean, ReceivedAt?: any | null, Dia: boolean, ParseTime: number, Priority: { __typename?: 'MessagePriority', Priority: number, Category: number }, Command: { __typename?: 'MessageCommand', Command: string, Args: Array<string | null> }, ExtraReceivers: Array<{ __typename?: 'MessageReceiver', CapCode: string, Function: string, Region: string, Discipline: string }>, Location?: { __typename?: 'MessageLocation', City: string, Street: string, Lat: number, Long: number } | null } };

export type GetMessagesForLocationQueryVariables = Exact<{
  lat?: InputMaybe<Scalars['Float']['input']>;
  long?: InputMaybe<Scalars['Float']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetMessagesForLocationQuery = { __typename?: 'Query', GetMessagesForLocation: { __typename?: 'PagedMessages', Total: number, Page: number, Limit: number, GPSLocation?: { __typename?: 'GPSLocation', Lat: number, Long: number } | null, Messages: Array<{ __typename?: 'Message', ID: number, UUID: string, Type: string, Date?: any | null, Message: string, RawBody: string, Unit: string, Receiver: string, ReceivedAt?: any | null, TestMessage: boolean, Dia: boolean, ParseTime: number, Command: { __typename?: 'MessageCommand', Command: string, Args: Array<string | null> }, Priority: { __typename?: 'MessagePriority', Priority: number, Category: number }, ExtraReceivers: Array<{ __typename?: 'MessageReceiver', CapCode: string, Function: string, Region: string, Discipline: string }>, Location?: { __typename?: 'MessageLocation', City: string, Street: string, Lat: number, Long: number } | null }> } };

export type LastMessagesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type LastMessagesQuery = { __typename?: 'Query', LastMessages: { __typename?: 'PagedMessages', Total: number, Page: number, Limit: number, GPSLocation?: { __typename?: 'GPSLocation', Lat: number, Long: number } | null, Messages: Array<{ __typename?: 'Message', ID: number, UUID: string, Type: string, Date?: any | null, Message: string, RawBody: string, Unit: string, Receiver: string, ReceivedAt?: any | null, TestMessage: boolean, Dia: boolean, ParseTime: number, Command: { __typename?: 'MessageCommand', Command: string, Args: Array<string | null> }, Priority: { __typename?: 'MessagePriority', Priority: number, Category: number }, ExtraReceivers: Array<{ __typename?: 'MessageReceiver', CapCode: string, Function: string, Region: string, Discipline: string }>, Location?: { __typename?: 'MessageLocation', City: string, Street: string, Lat: number, Long: number } | null }> } };

export type GetMessagesForSearchQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetMessagesForSearchQuery = { __typename?: 'Query', GetMessagesForSearch: { __typename?: 'PagedMessages', Total: number, Page: number, Limit: number, GPSLocation?: { __typename?: 'GPSLocation', Lat: number, Long: number } | null, Messages: Array<{ __typename?: 'Message', ID: number, UUID: string, Type: string, Date?: any | null, Message: string, RawBody: string, Unit: string, Receiver: string, ReceivedAt?: any | null, TestMessage: boolean, Dia: boolean, ParseTime: number, Command: { __typename?: 'MessageCommand', Command: string, Args: Array<string | null> }, Priority: { __typename?: 'MessagePriority', Priority: number, Category: number }, ExtraReceivers: Array<{ __typename?: 'MessageReceiver', CapCode: string, Function: string, Region: string, Discipline: string }>, Location?: { __typename?: 'MessageLocation', City: string, Street: string, Lat: number, Long: number } | null }> } };


export const DisableNotificationsForDeviceDocument = gql`
    mutation DisableNotificationsForDevice($OS: String = "ios", $token: String = "123", $mute: Boolean = true) {
  DisableNotificationsForDevice(OS: $OS, token: $token, mute: $mute)
}
    `;
export type DisableNotificationsForDeviceMutationFn = Apollo.MutationFunction<DisableNotificationsForDeviceMutation, DisableNotificationsForDeviceMutationVariables>;

/**
 * __useDisableNotificationsForDeviceMutation__
 *
 * To run a mutation, you first call `useDisableNotificationsForDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableNotificationsForDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableNotificationsForDeviceMutation, { data, loading, error }] = useDisableNotificationsForDeviceMutation({
 *   variables: {
 *      OS: // value for 'OS'
 *      token: // value for 'token'
 *      mute: // value for 'mute'
 *   },
 * });
 */
export function useDisableNotificationsForDeviceMutation(baseOptions?: Apollo.MutationHookOptions<DisableNotificationsForDeviceMutation, DisableNotificationsForDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableNotificationsForDeviceMutation, DisableNotificationsForDeviceMutationVariables>(DisableNotificationsForDeviceDocument, options);
      }
export type DisableNotificationsForDeviceMutationHookResult = ReturnType<typeof useDisableNotificationsForDeviceMutation>;
export type DisableNotificationsForDeviceMutationResult = Apollo.MutationResult<DisableNotificationsForDeviceMutation>;
export type DisableNotificationsForDeviceMutationOptions = Apollo.BaseMutationOptions<DisableNotificationsForDeviceMutation, DisableNotificationsForDeviceMutationVariables>;
export const EnableNotificationsForDeviceDocument = gql`
    mutation EnableNotificationsForDevice($OS: String, $token: String) {
  EnableNotificationsForDevice(OS: $OS, token: $token)
}
    `;
export type EnableNotificationsForDeviceMutationFn = Apollo.MutationFunction<EnableNotificationsForDeviceMutation, EnableNotificationsForDeviceMutationVariables>;

/**
 * __useEnableNotificationsForDeviceMutation__
 *
 * To run a mutation, you first call `useEnableNotificationsForDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableNotificationsForDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableNotificationsForDeviceMutation, { data, loading, error }] = useEnableNotificationsForDeviceMutation({
 *   variables: {
 *      OS: // value for 'OS'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useEnableNotificationsForDeviceMutation(baseOptions?: Apollo.MutationHookOptions<EnableNotificationsForDeviceMutation, EnableNotificationsForDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableNotificationsForDeviceMutation, EnableNotificationsForDeviceMutationVariables>(EnableNotificationsForDeviceDocument, options);
      }
export type EnableNotificationsForDeviceMutationHookResult = ReturnType<typeof useEnableNotificationsForDeviceMutation>;
export type EnableNotificationsForDeviceMutationResult = Apollo.MutationResult<EnableNotificationsForDeviceMutation>;
export type EnableNotificationsForDeviceMutationOptions = Apollo.BaseMutationOptions<EnableNotificationsForDeviceMutation, EnableNotificationsForDeviceMutationVariables>;
export const RemoveNotificationDocument = gql`
    mutation RemoveNotification($OS: String = "ios", $token: String = "123", $notification: String = "bla") {
  RemoveNotification(OS: $OS, token: $token, notification: $notification)
}
    `;
export type RemoveNotificationMutationFn = Apollo.MutationFunction<RemoveNotificationMutation, RemoveNotificationMutationVariables>;

/**
 * __useRemoveNotificationMutation__
 *
 * To run a mutation, you first call `useRemoveNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeNotificationMutation, { data, loading, error }] = useRemoveNotificationMutation({
 *   variables: {
 *      OS: // value for 'OS'
 *      token: // value for 'token'
 *      notification: // value for 'notification'
 *   },
 * });
 */
export function useRemoveNotificationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveNotificationMutation, RemoveNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveNotificationMutation, RemoveNotificationMutationVariables>(RemoveNotificationDocument, options);
      }
export type RemoveNotificationMutationHookResult = ReturnType<typeof useRemoveNotificationMutation>;
export type RemoveNotificationMutationResult = Apollo.MutationResult<RemoveNotificationMutation>;
export type RemoveNotificationMutationOptions = Apollo.BaseMutationOptions<RemoveNotificationMutation, RemoveNotificationMutationVariables>;
export const SetNotificationForDeviceDocument = gql`
    mutation SetNotificationForDevice($OS: String = "ios", $token: String = "123", $notification: String = "bla", $mute: Boolean = true) {
  SetNotificationForDevice(
    OS: $OS
    token: $token
    notification: $notification
    mute: $mute
  ) {
    Status
    ID
  }
}
    `;
export type SetNotificationForDeviceMutationFn = Apollo.MutationFunction<SetNotificationForDeviceMutation, SetNotificationForDeviceMutationVariables>;

/**
 * __useSetNotificationForDeviceMutation__
 *
 * To run a mutation, you first call `useSetNotificationForDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNotificationForDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNotificationForDeviceMutation, { data, loading, error }] = useSetNotificationForDeviceMutation({
 *   variables: {
 *      OS: // value for 'OS'
 *      token: // value for 'token'
 *      notification: // value for 'notification'
 *      mute: // value for 'mute'
 *   },
 * });
 */
export function useSetNotificationForDeviceMutation(baseOptions?: Apollo.MutationHookOptions<SetNotificationForDeviceMutation, SetNotificationForDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetNotificationForDeviceMutation, SetNotificationForDeviceMutationVariables>(SetNotificationForDeviceDocument, options);
      }
export type SetNotificationForDeviceMutationHookResult = ReturnType<typeof useSetNotificationForDeviceMutation>;
export type SetNotificationForDeviceMutationResult = Apollo.MutationResult<SetNotificationForDeviceMutation>;
export type SetNotificationForDeviceMutationOptions = Apollo.BaseMutationOptions<SetNotificationForDeviceMutation, SetNotificationForDeviceMutationVariables>;
export const GetLastForFavoritesDocument = gql`
    query GetLastForFavorites($limit: Int = 20, $page: Int = 1, $cities: [String], $capcodes: [Int]) {
  GetLastForFavorites(
    limit: $limit
    page: $page
    cities: $cities
    capCodes: $capcodes
  ) {
    Total
    Page
    Limit
    GPSLocation {
      Lat
      Long
    }
    Messages {
      ID
      UUID
      Type
      Command {
        Command
        Args
      }
      Date
      Message
      RawBody
      Priority {
        Priority
        Category
      }
      Unit
      Receiver
      ReceivedAt
      ExtraReceivers {
        CapCode
        Function
        Region
        Discipline
      }
      Location {
        City
        Street
        Lat
        Long
      }
      TestMessage
      Dia
      ParseTime
    }
  }
}
    `;

/**
 * __useGetLastForFavoritesQuery__
 *
 * To run a query within a React component, call `useGetLastForFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastForFavoritesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastForFavoritesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      cities: // value for 'cities'
 *      capcodes: // value for 'capcodes'
 *   },
 * });
 */
export function useGetLastForFavoritesQuery(baseOptions?: Apollo.QueryHookOptions<GetLastForFavoritesQuery, GetLastForFavoritesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLastForFavoritesQuery, GetLastForFavoritesQueryVariables>(GetLastForFavoritesDocument, options);
      }
export function useGetLastForFavoritesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLastForFavoritesQuery, GetLastForFavoritesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLastForFavoritesQuery, GetLastForFavoritesQueryVariables>(GetLastForFavoritesDocument, options);
        }
export function useGetLastForFavoritesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLastForFavoritesQuery, GetLastForFavoritesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLastForFavoritesQuery, GetLastForFavoritesQueryVariables>(GetLastForFavoritesDocument, options);
        }
export type GetLastForFavoritesQueryHookResult = ReturnType<typeof useGetLastForFavoritesQuery>;
export type GetLastForFavoritesLazyQueryHookResult = ReturnType<typeof useGetLastForFavoritesLazyQuery>;
export type GetLastForFavoritesSuspenseQueryHookResult = ReturnType<typeof useGetLastForFavoritesSuspenseQuery>;
export type GetLastForFavoritesQueryResult = Apollo.QueryResult<GetLastForFavoritesQuery, GetLastForFavoritesQueryVariables>;
export const GetMessagesForCitySearchDocument = gql`
    query GetMessagesForCitySearch($search: String = "Heemstederweg", $city: String = "castricum", $limit: Int = 100, $page: Int = 1) {
  GetMessagesForCitySearch(
    limit: $limit
    page: $page
    search: $search
    city: $city
  ) {
    Total
    Page
    Limit
    Messages {
      ID
      UUID
      Type
      Command {
        Command
        Args
      }
      Date
      Message
      RawBody
      Priority {
        Priority
        Category
      }
      Unit
      Receiver
      ReceivedAt
      ExtraReceivers {
        CapCode
        Function
        Region
        Discipline
      }
      Location {
        City
        Street
        Lat
        Long
      }
      TestMessage
      Dia
      ParseTime
    }
  }
}
    `;

/**
 * __useGetMessagesForCitySearchQuery__
 *
 * To run a query within a React component, call `useGetMessagesForCitySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessagesForCitySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessagesForCitySearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *      city: // value for 'city'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetMessagesForCitySearchQuery(baseOptions?: Apollo.QueryHookOptions<GetMessagesForCitySearchQuery, GetMessagesForCitySearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessagesForCitySearchQuery, GetMessagesForCitySearchQueryVariables>(GetMessagesForCitySearchDocument, options);
      }
export function useGetMessagesForCitySearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessagesForCitySearchQuery, GetMessagesForCitySearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessagesForCitySearchQuery, GetMessagesForCitySearchQueryVariables>(GetMessagesForCitySearchDocument, options);
        }
export function useGetMessagesForCitySearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMessagesForCitySearchQuery, GetMessagesForCitySearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMessagesForCitySearchQuery, GetMessagesForCitySearchQueryVariables>(GetMessagesForCitySearchDocument, options);
        }
export type GetMessagesForCitySearchQueryHookResult = ReturnType<typeof useGetMessagesForCitySearchQuery>;
export type GetMessagesForCitySearchLazyQueryHookResult = ReturnType<typeof useGetMessagesForCitySearchLazyQuery>;
export type GetMessagesForCitySearchSuspenseQueryHookResult = ReturnType<typeof useGetMessagesForCitySearchSuspenseQuery>;
export type GetMessagesForCitySearchQueryResult = Apollo.QueryResult<GetMessagesForCitySearchQuery, GetMessagesForCitySearchQueryVariables>;
export const HeartBeatDocument = gql`
    query HeartBeat {
  Heartbeat {
    Alive
    Last
  }
}
    `;

/**
 * __useHeartBeatQuery__
 *
 * To run a query within a React component, call `useHeartBeatQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeartBeatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeartBeatQuery({
 *   variables: {
 *   },
 * });
 */
export function useHeartBeatQuery(baseOptions?: Apollo.QueryHookOptions<HeartBeatQuery, HeartBeatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HeartBeatQuery, HeartBeatQueryVariables>(HeartBeatDocument, options);
      }
export function useHeartBeatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeartBeatQuery, HeartBeatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HeartBeatQuery, HeartBeatQueryVariables>(HeartBeatDocument, options);
        }
export function useHeartBeatSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HeartBeatQuery, HeartBeatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HeartBeatQuery, HeartBeatQueryVariables>(HeartBeatDocument, options);
        }
export type HeartBeatQueryHookResult = ReturnType<typeof useHeartBeatQuery>;
export type HeartBeatLazyQueryHookResult = ReturnType<typeof useHeartBeatLazyQuery>;
export type HeartBeatSuspenseQueryHookResult = ReturnType<typeof useHeartBeatSuspenseQuery>;
export type HeartBeatQueryResult = Apollo.QueryResult<HeartBeatQuery, HeartBeatQueryVariables>;
export const FindByCapCodeDocument = gql`
    query FindByCapCode($limit: Int = 100, $page: Int = 1, $capCode: Int = 200000) {
  FindByCapCode(limit: $limit, page: $page, capCode: $capCode) {
    Total
    Page
    Limit
    GPSLocation {
      Lat
      Long
    }
    Messages {
      ID
      UUID
      Type
      Command {
        Command
        Args
      }
      Date
      Message
      RawBody
      Priority {
        Priority
        Category
      }
      Unit
      Receiver
      ReceivedAt
      ExtraReceivers {
        CapCode
        Function
        Region
        Discipline
      }
      Location {
        City
        Street
        Lat
        Long
      }
      TestMessage
      Dia
      ParseTime
    }
  }
}
    `;

/**
 * __useFindByCapCodeQuery__
 *
 * To run a query within a React component, call `useFindByCapCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindByCapCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindByCapCodeQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      capCode: // value for 'capCode'
 *   },
 * });
 */
export function useFindByCapCodeQuery(baseOptions?: Apollo.QueryHookOptions<FindByCapCodeQuery, FindByCapCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindByCapCodeQuery, FindByCapCodeQueryVariables>(FindByCapCodeDocument, options);
      }
export function useFindByCapCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindByCapCodeQuery, FindByCapCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindByCapCodeQuery, FindByCapCodeQueryVariables>(FindByCapCodeDocument, options);
        }
export function useFindByCapCodeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindByCapCodeQuery, FindByCapCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindByCapCodeQuery, FindByCapCodeQueryVariables>(FindByCapCodeDocument, options);
        }
export type FindByCapCodeQueryHookResult = ReturnType<typeof useFindByCapCodeQuery>;
export type FindByCapCodeLazyQueryHookResult = ReturnType<typeof useFindByCapCodeLazyQuery>;
export type FindByCapCodeSuspenseQueryHookResult = ReturnType<typeof useFindByCapCodeSuspenseQuery>;
export type FindByCapCodeQueryResult = Apollo.QueryResult<FindByCapCodeQuery, FindByCapCodeQueryVariables>;
export const FindByCityDocument = gql`
    query FindByCity($limit: Int = 100, $page: Int = 1, $city: String = "castricum") {
  FindByCity(limit: $limit, page: $page, city: $city) {
    Total
    Page
    Limit
    GPSLocation {
      Lat
      Long
    }
    Messages {
      ID
      UUID
      Type
      Command {
        Command
        Args
      }
      Date
      Message
      RawBody
      Priority {
        Priority
        Category
      }
      Unit
      Receiver
      ReceivedAt
      ExtraReceivers {
        CapCode
        Function
        Region
        Discipline
      }
      Location {
        City
        Street
        Lat
        Long
      }
      TestMessage
      Dia
      ParseTime
    }
  }
}
    `;

/**
 * __useFindByCityQuery__
 *
 * To run a query within a React component, call `useFindByCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindByCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindByCityQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      city: // value for 'city'
 *   },
 * });
 */
export function useFindByCityQuery(baseOptions?: Apollo.QueryHookOptions<FindByCityQuery, FindByCityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindByCityQuery, FindByCityQueryVariables>(FindByCityDocument, options);
      }
export function useFindByCityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindByCityQuery, FindByCityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindByCityQuery, FindByCityQueryVariables>(FindByCityDocument, options);
        }
export function useFindByCitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindByCityQuery, FindByCityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindByCityQuery, FindByCityQueryVariables>(FindByCityDocument, options);
        }
export type FindByCityQueryHookResult = ReturnType<typeof useFindByCityQuery>;
export type FindByCityLazyQueryHookResult = ReturnType<typeof useFindByCityLazyQuery>;
export type FindByCitySuspenseQueryHookResult = ReturnType<typeof useFindByCitySuspenseQuery>;
export type FindByCityQueryResult = Apollo.QueryResult<FindByCityQuery, FindByCityQueryVariables>;
export const GetCapCodeDocument = gql`
    query GetCapCode($capCode: Int = 100) {
  GetCapCode(capCode: $capCode) {
    CapCode
    Function
    Region
    Discipline
  }
}
    `;

/**
 * __useGetCapCodeQuery__
 *
 * To run a query within a React component, call `useGetCapCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCapCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCapCodeQuery({
 *   variables: {
 *      capCode: // value for 'capCode'
 *   },
 * });
 */
export function useGetCapCodeQuery(baseOptions?: Apollo.QueryHookOptions<GetCapCodeQuery, GetCapCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCapCodeQuery, GetCapCodeQueryVariables>(GetCapCodeDocument, options);
      }
export function useGetCapCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCapCodeQuery, GetCapCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCapCodeQuery, GetCapCodeQueryVariables>(GetCapCodeDocument, options);
        }
export function useGetCapCodeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCapCodeQuery, GetCapCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCapCodeQuery, GetCapCodeQueryVariables>(GetCapCodeDocument, options);
        }
export type GetCapCodeQueryHookResult = ReturnType<typeof useGetCapCodeQuery>;
export type GetCapCodeLazyQueryHookResult = ReturnType<typeof useGetCapCodeLazyQuery>;
export type GetCapCodeSuspenseQueryHookResult = ReturnType<typeof useGetCapCodeSuspenseQuery>;
export type GetCapCodeQueryResult = Apollo.QueryResult<GetCapCodeQuery, GetCapCodeQueryVariables>;
export const GetCapCodesDocument = gql`
    query GetCapCodes($limit: Int = 100, $page: Int = 1) {
  GetCapCodes(limit: $limit, page: $page) {
    Total
    Page
    Limit
    CapCodes {
      CapCode
      Function
      Region
      Discipline
    }
  }
}
    `;

/**
 * __useGetCapCodesQuery__
 *
 * To run a query within a React component, call `useGetCapCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCapCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCapCodesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetCapCodesQuery(baseOptions?: Apollo.QueryHookOptions<GetCapCodesQuery, GetCapCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCapCodesQuery, GetCapCodesQueryVariables>(GetCapCodesDocument, options);
      }
export function useGetCapCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCapCodesQuery, GetCapCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCapCodesQuery, GetCapCodesQueryVariables>(GetCapCodesDocument, options);
        }
export function useGetCapCodesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCapCodesQuery, GetCapCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCapCodesQuery, GetCapCodesQueryVariables>(GetCapCodesDocument, options);
        }
export type GetCapCodesQueryHookResult = ReturnType<typeof useGetCapCodesQuery>;
export type GetCapCodesLazyQueryHookResult = ReturnType<typeof useGetCapCodesLazyQuery>;
export type GetCapCodesSuspenseQueryHookResult = ReturnType<typeof useGetCapCodesSuspenseQuery>;
export type GetCapCodesQueryResult = Apollo.QueryResult<GetCapCodesQuery, GetCapCodesQueryVariables>;
export const GetCitiesDocument = gql`
    query GetCities {
  GetCities {
    Name
    Abbreviation
  }
}
    `;

/**
 * __useGetCitiesQuery__
 *
 * To run a query within a React component, call `useGetCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetCitiesQuery, GetCitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCitiesQuery, GetCitiesQueryVariables>(GetCitiesDocument, options);
      }
export function useGetCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCitiesQuery, GetCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCitiesQuery, GetCitiesQueryVariables>(GetCitiesDocument, options);
        }
export function useGetCitiesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCitiesQuery, GetCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCitiesQuery, GetCitiesQueryVariables>(GetCitiesDocument, options);
        }
export type GetCitiesQueryHookResult = ReturnType<typeof useGetCitiesQuery>;
export type GetCitiesLazyQueryHookResult = ReturnType<typeof useGetCitiesLazyQuery>;
export type GetCitiesSuspenseQueryHookResult = ReturnType<typeof useGetCitiesSuspenseQuery>;
export type GetCitiesQueryResult = Apollo.QueryResult<GetCitiesQuery, GetCitiesQueryVariables>;
export const GetMessageDocument = gql`
    query GetMessage($uuid: String, $id: Int = null) {
  GetMessage(id: $id, uuid: $uuid) {
    ID
    UUID
    Type
    Date
    Priority {
      Priority
      Category
    }
    TechCode
    RawBody
    Unit
    Command {
      Command
      Args
    }
    Receiver
    ExtraReceivers {
      CapCode
      Function
      Region
      Discipline
    }
    Message
    Location {
      City
      Street
      Lat
      Long
    }
    TestMessage
    ReceivedAt
    Dia
    ParseTime
  }
}
    `;

/**
 * __useGetMessageQuery__
 *
 * To run a query within a React component, call `useGetMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMessageQuery(baseOptions?: Apollo.QueryHookOptions<GetMessageQuery, GetMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessageQuery, GetMessageQueryVariables>(GetMessageDocument, options);
      }
export function useGetMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessageQuery, GetMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessageQuery, GetMessageQueryVariables>(GetMessageDocument, options);
        }
export function useGetMessageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMessageQuery, GetMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMessageQuery, GetMessageQueryVariables>(GetMessageDocument, options);
        }
export type GetMessageQueryHookResult = ReturnType<typeof useGetMessageQuery>;
export type GetMessageLazyQueryHookResult = ReturnType<typeof useGetMessageLazyQuery>;
export type GetMessageSuspenseQueryHookResult = ReturnType<typeof useGetMessageSuspenseQuery>;
export type GetMessageQueryResult = Apollo.QueryResult<GetMessageQuery, GetMessageQueryVariables>;
export const GetMessagesForLocationDocument = gql`
    query GetMessagesForLocation($lat: Float, $long: Float, $limit: Int = 100, $page: Int = 1) {
  GetMessagesForLocation(lat: $lat, long: $long, limit: $limit, page: $page) {
    Total
    Page
    Limit
    GPSLocation {
      Lat
      Long
    }
    Messages {
      ID
      UUID
      Type
      Command {
        Command
        Args
      }
      Date
      Message
      RawBody
      Priority {
        Priority
        Category
      }
      Unit
      Receiver
      ReceivedAt
      ExtraReceivers {
        CapCode
        Function
        Region
        Discipline
      }
      Location {
        City
        Street
        Lat
        Long
      }
      TestMessage
      Dia
      ParseTime
    }
  }
}
    `;

/**
 * __useGetMessagesForLocationQuery__
 *
 * To run a query within a React component, call `useGetMessagesForLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessagesForLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessagesForLocationQuery({
 *   variables: {
 *      lat: // value for 'lat'
 *      long: // value for 'long'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetMessagesForLocationQuery(baseOptions?: Apollo.QueryHookOptions<GetMessagesForLocationQuery, GetMessagesForLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessagesForLocationQuery, GetMessagesForLocationQueryVariables>(GetMessagesForLocationDocument, options);
      }
export function useGetMessagesForLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessagesForLocationQuery, GetMessagesForLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessagesForLocationQuery, GetMessagesForLocationQueryVariables>(GetMessagesForLocationDocument, options);
        }
export function useGetMessagesForLocationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMessagesForLocationQuery, GetMessagesForLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMessagesForLocationQuery, GetMessagesForLocationQueryVariables>(GetMessagesForLocationDocument, options);
        }
export type GetMessagesForLocationQueryHookResult = ReturnType<typeof useGetMessagesForLocationQuery>;
export type GetMessagesForLocationLazyQueryHookResult = ReturnType<typeof useGetMessagesForLocationLazyQuery>;
export type GetMessagesForLocationSuspenseQueryHookResult = ReturnType<typeof useGetMessagesForLocationSuspenseQuery>;
export type GetMessagesForLocationQueryResult = Apollo.QueryResult<GetMessagesForLocationQuery, GetMessagesForLocationQueryVariables>;
export const LastMessagesDocument = gql`
    query LastMessages($limit: Int = 100, $page: Int = 1) {
  LastMessages(limit: $limit, page: $page) {
    Total
    Page
    Limit
    Total
    Page
    Limit
    GPSLocation {
      Lat
      Long
    }
    Messages {
      ID
      UUID
      Type
      Command {
        Command
        Args
      }
      Date
      Message
      RawBody
      Priority {
        Priority
        Category
      }
      Unit
      Receiver
      ReceivedAt
      ExtraReceivers {
        CapCode
        Function
        Region
        Discipline
      }
      Location {
        City
        Street
        Lat
        Long
      }
      TestMessage
      Dia
      ParseTime
    }
  }
}
    `;

/**
 * __useLastMessagesQuery__
 *
 * To run a query within a React component, call `useLastMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastMessagesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLastMessagesQuery(baseOptions?: Apollo.QueryHookOptions<LastMessagesQuery, LastMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LastMessagesQuery, LastMessagesQueryVariables>(LastMessagesDocument, options);
      }
export function useLastMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LastMessagesQuery, LastMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LastMessagesQuery, LastMessagesQueryVariables>(LastMessagesDocument, options);
        }
export function useLastMessagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LastMessagesQuery, LastMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LastMessagesQuery, LastMessagesQueryVariables>(LastMessagesDocument, options);
        }
export type LastMessagesQueryHookResult = ReturnType<typeof useLastMessagesQuery>;
export type LastMessagesLazyQueryHookResult = ReturnType<typeof useLastMessagesLazyQuery>;
export type LastMessagesSuspenseQueryHookResult = ReturnType<typeof useLastMessagesSuspenseQuery>;
export type LastMessagesQueryResult = Apollo.QueryResult<LastMessagesQuery, LastMessagesQueryVariables>;
export const GetMessagesForSearchDocument = gql`
    query GetMessagesForSearch($search: String, $page: Int = 1, $limit: Int = 50) {
  GetMessagesForSearch(search: $search, page: $page, limit: $limit) {
    Total
    Page
    Limit
    GPSLocation {
      Lat
      Long
    }
    Messages {
      ID
      UUID
      Type
      Command {
        Command
        Args
      }
      Date
      Message
      RawBody
      Priority {
        Priority
        Category
      }
      Unit
      Receiver
      ReceivedAt
      ExtraReceivers {
        CapCode
        Function
        Region
        Discipline
      }
      Location {
        City
        Street
        Lat
        Long
      }
      TestMessage
      Dia
      ParseTime
    }
  }
}
    `;

/**
 * __useGetMessagesForSearchQuery__
 *
 * To run a query within a React component, call `useGetMessagesForSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessagesForSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessagesForSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetMessagesForSearchQuery(baseOptions?: Apollo.QueryHookOptions<GetMessagesForSearchQuery, GetMessagesForSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessagesForSearchQuery, GetMessagesForSearchQueryVariables>(GetMessagesForSearchDocument, options);
      }
export function useGetMessagesForSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessagesForSearchQuery, GetMessagesForSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessagesForSearchQuery, GetMessagesForSearchQueryVariables>(GetMessagesForSearchDocument, options);
        }
export function useGetMessagesForSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMessagesForSearchQuery, GetMessagesForSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMessagesForSearchQuery, GetMessagesForSearchQueryVariables>(GetMessagesForSearchDocument, options);
        }
export type GetMessagesForSearchQueryHookResult = ReturnType<typeof useGetMessagesForSearchQuery>;
export type GetMessagesForSearchLazyQueryHookResult = ReturnType<typeof useGetMessagesForSearchLazyQuery>;
export type GetMessagesForSearchSuspenseQueryHookResult = ReturnType<typeof useGetMessagesForSearchSuspenseQuery>;
export type GetMessagesForSearchQueryResult = Apollo.QueryResult<GetMessagesForSearchQuery, GetMessagesForSearchQueryVariables>;